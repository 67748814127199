export * from './client.service';
import { ClientService } from './client.service';
export * from './msa.service';
import { MsaService } from './msa.service';
export * from './project.service';
import { ProjectService } from './project.service';
export * from './projectReport.service';
import { ProjectReportService } from './projectReport.service';
export * from './projectWorklog.service';
import { ProjectWorklogService } from './projectWorklog.service';
export * from './srf.service';
import { SrfService } from './srf.service';
export * from './srfReport.service';
import { SrfReportService } from './srfReport.service';
export * from './srfWorklog.service';
import { SrfWorklogService } from './srfWorklog.service';
export * from './user.service';
import { UserService } from './user.service';
export const APIS = [ClientService, MsaService, ProjectService, ProjectReportService, ProjectWorklogService, SrfService, SrfReportService, SrfWorklogService, UserService];
